import React from "react"
import styled from "styled-components"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Page from "../components/page"
import Wrap from "../components/wrap"

const PageCourses = ({ data }) => {
  return (
    <Page title="コース紹介">
      <Wrap>
        <Courses>
          <li className="full">
            <a href="https://dj-zero.com/" target="_blank" rel="noopener noreferrer">
              <Img fluid={data.imgBannerZero.childImageSharp.fluid} />
            </a>
          </li>
          <li className="half">
            <Link to="/course/dj">
              <Img fluid={data.imgBannerDj.childImageSharp.fluid} />
            </Link>
          </li>
          <li className="half">
            <Link to="/course/dtm">
              <Img fluid={data.imgBannerDtm.childImageSharp.fluid} />
            </Link>
          </li>
          <li className="half">
            <Link to="/course/vj">
              <Img fluid={data.imgBannerVj.childImageSharp.fluid} />
            </Link>
          </li>
          <li className="half">
            <Link to="/course/mc">
              <Img fluid={data.imgBannerMc.childImageSharp.fluid} />
            </Link>
          </li>
          <li className="half">
            <Link to="/course/kids">
              <Img fluid={data.imgBannerKids.childImageSharp.fluid} />
            </Link>
          </li>
        </Courses>
      </Wrap>
    </Page>
  )
}

export default PageCourses

export const pageQuery = graphql`
  query {
    imgBannerZero: file(relativePath: { eq: "course-banner-zero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgBannerDj: file(relativePath: { eq: "course-banner-dj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgBannerDtm: file(relativePath: { eq: "course-banner-dtm.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgBannerVj: file(relativePath: { eq: "course-banner-vj.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgBannerMc: file(relativePath: { eq: "course-banner-mc.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imgBannerKids: file(relativePath: { eq: "course-banner-kids.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Courses = styled.ul`
  list-style: none;
  margin: 2rem auto;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;

  li {
  }

  .full {
    grid-column: 1 / -1;
  }

  .half {
  }
`
